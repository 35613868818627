import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { AuthService } from '../services/auth.service';
import { UserAccess } from '../interfaces/user.interface';

export const NoUserAuthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const router = inject(Router);
    const sessionStorageService = inject(SessionStorageService);
    const authService = inject(AuthService);

    const userAccess: UserAccess = sessionStorageService.getObject('userAccess');
    authService.checkTokenValidity()

    if (userAccess && !authService.isTokenExpired(userAccess.accessToken)) {
        router.navigate(['/pages/workspaces/manager']);
        return false
    } else {
        return true
    }
};