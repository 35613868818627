import { SharedService } from '@_metronic/shared/services/shared.service';
import { ChangeDetectorRef,Component, OnInit,Input } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { and } from 'firebase/firestore';

interface Campo {
  options: string;
  id: number;
  helpInfo: string;
  description: string;
  icon: string;
  name: string;
  placeholder: string;
  type: string;
  required: boolean;
}

interface NuevoFormulario {
  [key: string]: string[];
}

interface Field {
  id: number;
  name: string;
}

interface JSONObj {
  sectionName: string;
  shortDescription: string;
  fields: Field[];
  id: number;
}

//Interfaces para el nuevo objeto de datos ""
interface ObjetoA {
  [key: string]: string;
}

interface ObjetoB {
  [key: string]: string;
}
@Component({
  selector: 'app-visor-active-docu',
  templateUrl: './visor-active-docu.component.html',
  styleUrls: ['./visor-active-docu.component.scss']
})
export class VisorActiveDocuComponent implements OnInit {
  //@Description: Guardamos el valor recorrido, para poder poner el estatus de los steps
  currentStep$: BehaviorSubject<number> = new BehaviorSubject(1);

  miFormulario: FormGroup;

  private activeIDSubscription: Subscription | undefined;

  sections:any = [];
  fieldData:any = [];
  currentForm: Campo[] = [];

  sectionStates: boolean[] = [];
  funcionOk: boolean = false;

  secctions:any;

  showButton:boolean;

  currentIndex:number = 0;

  allData:any;

  constructor( private formBuilder: FormBuilder, private shSvc: SharedService,private cdr: ChangeDetectorRef) {
     this.sectionStates = this.sections.map(() => false);
     this.sectionStates[0] = true;
  }

  ngOnInit(): void {
    this.activeIDSubscription = this.shSvc.activeID.subscribe(data => {
     this.getActiveDocu(data);

     console.log(data)
     this.allData = data;
     this.secctions = data.obj;
     this.currentForm = data.obj[0].fields;
    }); 
  }

  ngOnDestroy(): void {
    if (this.activeIDSubscription) {
      this.activeIDSubscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.miFormulario.valid) {
      const objetoA = this.obtenerObjetoIdYFieldName(this.allData.obj[this.currentIndex]);
      const objetoB = this.miFormulario.value;

      const id = this.allData.id;
      const data = this.cruzarObjetos(objetoA, objetoB);
      
      console.log(id, data);
      
      this.shSvc.updateActiveDocu(id,data);
    }
  }

  obtenerObjetoIdYFieldName(objeto: JSONObj): Record<number, string> {
    const resultado: Record<number, string> = {};
    objeto.fields.forEach((field: Field) => {
        resultado[field.id] = field.name;
    });
    return resultado;
  }

  cruzarObjetos(objetoA: ObjetoA, objetoB: ObjetoB): ObjetoA {
    const nuevoObjeto: ObjetoA = {};

    for (const key in objetoA) {
        if (objetoA.hasOwnProperty(key)) {
            const nombreAtributo = objetoA[key];
            nuevoObjeto[key] = objetoB[nombreAtributo] || ''; // Si el atributo no existe en el objeto B, asignamos una cadena vacía
        }
    }

    return nuevoObjeto;
  }

  //detectar cambios en formulario
  onChange(event: any) {
    if(event.target.value){
      this.showButton = true;
    }
  }

  async getActiveDocu(data: any| undefined ) {
    let selectedActiveDocu = await this.shSvc.activeDocu(data.id);
    let selectedDocuType = data.obj;

    this.sections  = selectedDocuType;
    this.fieldData  = Object.entries(selectedActiveDocu?.data);

    this.miFormulario = this.formBuilder.group(this.transformarFormulario(this.currentForm));

    this.funcionOk = true;

    this.cdr.detectChanges();
  }

  getFieldData(fieldId: number): string {
    const fieldData = this.fieldData.find((item: any) => item[0] === fieldId.toString());
    return fieldData ? fieldData[1] : '';
  }

  toggleSection(index: number): void {
    this.currentIndex = index;
    this.currentForm =  this.secctions[index].fields
    this.miFormulario = this.formBuilder.group(this.transformarFormulario(this.currentForm));

    const nextIndex = index +1;
    nextIndex <= this.sections.length ? this.currentStep$.next(nextIndex): this.currentStep$.next(index)
    this.sectionStates = this.sectionStates.map(() => false);
    this.sectionStates[index] = true;
  }

  //Función para convertir los tipos de datos
  transformarFormulario(formulario: Campo[]): NuevoFormulario {
    const nuevoFormulario: NuevoFormulario = {};

    formulario.forEach(campo => {
        nuevoFormulario[campo.name] = [this.getFieldData(campo.id)];
    });

    return nuevoFormulario;
  }
}
