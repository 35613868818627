import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { UserAccess } from 'src/app/auth/interfaces/user.interface';
import { User } from 'src/app/core/interfaces/user-model.interface';
import { CRUDMethodsService } from 'src/app/core/services/CRUD-methods-firestore.service';
import { FireStoreCollectionsService } from 'src/app/shared/services/fire-store-collections.service';
import { ImageUploaderService } from 'src/app/shared/services/image-uploader.service';

@Injectable({ providedIn: 'root' })
export class UserSessionService {
  private userDataSubject: BehaviorSubject<UserAccess | null> = new BehaviorSubject<UserAccess | null>(null);
  private user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private newUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private crudSvc: CRUDMethodsService,
    private fscSvc: FireStoreCollectionsService,
    private imageUploadSvc: ImageUploaderService

  ) {
    //this.loadUserInformation()
  }

  setUser(user: User | null) { this.user.next(user) }
  getUser(): Observable<User | null> { return this.user.asObservable() }

  setNewUser(user: User) { this.newUser.next(user) }
  getNewUser(): Observable<User | null> { return this.newUser.asObservable() }

  setUserAccess(userData: UserAccess): void { this.userDataSubject.next(userData) }
  getUserAccess() { return this.userDataSubject.asObservable() }
  getCurrentUserAccess(): UserAccess | null { return this.userDataSubject.value }


  setUid(uid: string): void {
    const currentUserAccess = this.userDataSubject.value;
    if (currentUserAccess) {
      this.userDataSubject.next({ ...currentUserAccess, uid });
    }
  }

  setAccessToken(accessToken: string): void {
    const currentUserAccess = this.userDataSubject.value;
    if (currentUserAccess) {
      this.userDataSubject.next({ ...currentUserAccess, accessToken });
    }
  }

  getUid(): string | null {
    const currentUserAccess = this.userDataSubject.value;
    return currentUserAccess ? currentUserAccess.uid : null;
  }

  getAccessToken(): string | null {
    const currentUserAccess = this.userDataSubject.value;
    return currentUserAccess ? currentUserAccess.accessToken : null;
  }

  loadUserInformation(): Observable<User | null> {
    const userUid = this.getUid();
    if (userUid) {
      return this.crudSvc.getDocumentById(userUid, this.fscSvc.usersCollection).pipe(
        switchMap((userData: User) => {
          if (userData) {
            if (userData.gcImageUrl) {
              return this.imageUploadSvc.getUrlFile(userData.gcImageUrl).pipe(
                switchMap(url => {
                  const updatedUser = { ...userData, imageUrl: url };
                  this.setUser(updatedUser);
                  return of(updatedUser);
                })
              );
            } else {
              this.setUser(userData);
              return of(userData)
            }
          } else {
            return of(null)
          }

        })
      );
    } else {
      return of(null);
    }
  }


}