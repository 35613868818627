<div class="main-body-collection">
  <div>

    <div class="body-steps stepper stepper-pills">
      <div class="centerCard stepper-item pdtop" *ngFor="let section of sections; let i = index"
        (click)="toggleSection(i)"
        [ngClass]="{current: currentStep$.value === i +1,completed: currentStep$.value > i +1}">
        <div class="d-flex">
          <div class="stepper-icon w-40px h-40px cursor-pointer">
            <i class="stepper-check fas fa-check"></i>
            <span class="stepper-number">{{ i + 1 }}</span>
          </div>
          <div class="d-flex flex-column">
            <label class="text-dark text-hover-primary fs-6 fw-bolder"
              [ngClass]="{activeTitle: currentStep$.value === i +1,completedTitle: currentStep$.value > i +1}">{{section.sectionName}}</label>
            <span class="text-muted fw-bold">{{section.shortDescription}}</span>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div>
    <div class="container" style="--bs-gutter-x:0;" *ngFor="let section of sections; let i = index">
      <div class="card mb-5 mb-xl-10 p-10" *ngIf="sectionStates[i]">

        <div class="card-title">
          <h3 class="fw-bolder">{{ section.sectionName }}</h3>
        </div>

        <ng-container *ngIf="funcionOk">

          <form class="card-body" [formGroup]="miFormulario" (ngSubmit)="onSubmit()" style="padding-bottom: 0;">
            <div *ngFor="let field of section.fields">
              <div class="row mb-6" style="justify-content: space-between;">
                <label class="col-lg-2 col-form-label fw-bold fs-3" style="width: auto;max-width: 50%;">
                  {{field.name}} :
                </label>
                <div class="col-lg-6 fv-row" style="display: flex;align-items: center;gap: 1em;">
                  <input type="text" (change)="onChange($event)"
                    class="inputText form-control form-control-lg form-control-solid"
                    formControlName="{{ field.name }}">

                  <i class="bi bi-pencil-square"></i>
                </div>
              </div>
            </div>

            <ng-container *ngIf="showButton">
              <div class="card-footer d-flex justify-content-center" style="padding-bottom: 0;">

                <div>
                  <button type="button" class="m-3 btn btn-secondary btn-sm">Cancelar</button>
                </div>

                <div>
                  <button type="submit" class="m-3 btn btn-primary btn-sm">Guardar</button>
                </div>

              </div>
            </ng-container>


          </form>
        </ng-container>
      </div>
    </div>

  </div>
</div>