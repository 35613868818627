import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, doc, DocumentData, DocumentReference, Firestore, getDoc, onSnapshot, query, QuerySnapshot, runTransaction, setDoc, Transaction, updateDoc } from '@angular/fire/firestore';
import { catchError, forkJoin, from, map, Observable, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CRUDMethodsService {
  constructor(private firestore: Firestore) { }

  /**
 * Lee todos los documentos de la colección que se manda como parámetro.
 * 
 * @param collectionName - Nombre de la colección con la que se va a trabajar.
 * @returns Un Observable que emite un array de documentos de la colección actual.
 */
  public readDocuments(collectionName: string): Observable<{ uid: string; data: DocumentData }[]> {
    const collectionRef = collection(this.firestore, collectionName);
    const q = query(collectionRef);

    return new Observable<{ uid: string; data: DocumentData }[]>((observer) => {
      const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
        const result = querySnapshot.docs.map(doc => ({
          uid: doc.id,
          data: doc.data()
        }));
        observer.next(result);
      }, (error) => {
        observer.error(error);
      });

      return () => unsubscribe();
    }).pipe(
      catchError(this.handleError)
    );
  }


  /**
 * Crea un nuevo documento en la colección especificada con los datos proporcionados.
 * 
 * Este método agrega un documento a la colección y devuelve la referencia del documento creado.
 * 
 * @param data - Los datos del documento que se va a crear.
 * @param collectionName - El nombre de la colección en la que se va a crear el documento.
 * @param uid - (Opcional) El ID del documento que se va a crear. Si no se proporciona, se generará uno automáticamente.
 * @returns Un Observable que emite la referencia del documento creado.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la creación del documento.
 */
  public createDocument(data: any, collectionName: string, uid?: string): Observable<{ uid: string; data: any }> {
    const collectionRef = collection(this.firestore, collectionName);
    if (uid) {
      const docRef = doc(collectionRef, uid);
      return from(setDoc(docRef, data)).pipe(
        map(() => {
          return { uid, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    } else {
      return from(addDoc(collectionRef, data)).pipe(
        map((docRef) => {
          const id = docRef.id;
          return { uid: id, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    }
  }

  /**
 * Crea o actualiza un documento en la colección especificada con los datos proporcionados.
 * 
 * Este método verifica si el documento con el ID proporcionado ya existe en la colección.
 * Si existe, lo actualiza con los nuevos datos; si no existe, lo crea.
 * 
 * @param data - Los datos del documento que se va a crear o actualizar.
 * @param collectionName - El nombre de la colección en la que se va a crear o actualizar el documento.
 * @param uid - El ID del documento que se va a crear o actualizar. Si no se proporciona, se generará uno automáticamente.
 * @returns Un Observable que emite la referencia del documento creado o actualizado.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la creación o actualización del documento.
 */
  public createOrUpdateDocument(data: any, collectionName: string, uid: string): Observable<{ uid: string; data: any }> {
    const collectionRef = collection(this.firestore, collectionName);
    const docRef = doc(collectionRef, uid);

    return from(getDoc(docRef)).pipe(
      switchMap((docSnap) => {
        if (docSnap.exists()) {
          // El documento existe, actualízalo.
          return from(updateDoc(docRef, data)).pipe(
            map(() => {
              return { uid, data };
            })
          );
        } else {
          // El documento no existe, créalo.
          return from(setDoc(docRef, data)).pipe(
            map(() => {
              return { uid, data };
            })
          );
        }
      }),
      catchError((error) => {
        console.error('Error al crear o actualizar el documento:', error);
        return throwError(() => error);
      })
    );
  }

  /**
 * Actualiza un campo específico de un documento en la colección por su ID.
 * @param documentId El ID del documento que se va a actualizar.
 * @param fieldName El nombre del campo que se va a actualizar.
 * @param value El nuevo valor del campo.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocumentField(documentId: string, fieldName: string, value: any, collectionName: string): Observable<any> {
    const documentRef = doc(collection(this.firestore, collectionName), documentId);
    const updateData = { [fieldName]: value };

    return from(updateDoc(documentRef, updateData)).pipe(
      switchMap(() => {
        return from(getDoc(documentRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data();
              return { uid: id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error('Error al obtener el documento después de la actualización:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el campo del documento:', error);
        throw error; // Propaga el error
      })
    );
  }



  /**
   * Obtiene un documento de una colección por su ID. Si no lo encuentra, lo crea con los datos proporcionados.
   * 
   * @param documentId - El ID del documento que se va a obtener.
   * @param collectionName - El nombre de la colección en la que se encuentra el documento.
   * @param data - Los datos con los que se creará el documento si no existe.
   * @returns Un Observable que emite el documento encontrado o creado.
   */
  public getOrCreateDocument(documentId: string, collectionName: string, data: any): Observable<any> {
    const docRef = doc(this.firestore, collectionName, documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          // Documento encontrado, retornarlo
          const id = docSnapshot.id;
          const documentData = docSnapshot.data();
          return { uid: id, ...documentData };
        } else {
          // Documento no encontrado, crear uno nuevo con los datos proporcionados
          return from(setDoc(docRef, data, { merge: true })).pipe(
            tap(console.log),
            map(() => ({ uid: documentId, ...data })), // Retorna el documento creado
            catchError(this.handleError) // Manejo de errores al crear el documento
          );
        }
      }),
      catchError(this.handleError) // Manejo de errores al obtener el documento
    );
  }

  public createDocumentCollectionReference(data: any, collectionRef: CollectionReference<DocumentData>, uid?: string): Observable<{ uid: string; data: any }> {
    if (uid) {
      const docRef = doc(collectionRef, uid);
      return from(setDoc(docRef, data)).pipe(
        map(() => {
          return { uid, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    } else {
      return from(addDoc(collectionRef, data)).pipe(
        map((docRef) => {
          const id = docRef.id;
          return { uid: id, data };
        }),
        catchError((error) => {
          console.error('Error al crear el documento:', error);
          return throwError(() => error);
        })
      );
    }
  }


  /**
     * Obtiene un documento de una colección por su ID.
     * 
     * @param documentId - El ID del documento que se va a obtener.
     * @param collectionName - El nombre de la colección en la que se encuentra el documento.
     * @returns Un Observable que emite el documento encontrado o un error si no se encuentra.
     */
  public getDocumentById(documentId: string, collectionName: string): Observable<any> {
    const docRef = doc(collection(this.firestore, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          const id = docSnapshot.id;
          const data = docSnapshot.data();
          return { uid: id, ...data };
        } else {
          return null
        }
      }),
      catchError(this.handleError)
    );
  }

  /**
 * Obtiene documentos a partir de un arreglo de DocumentReference.
 * 
 * Este método consulta los documentos referenciados y devuelve un Observable que emite un array de documentos.
 * 
 * @param references - Un arreglo de DocumentReference que representa los documentos a recuperar.
 * @returns Un Observable que emite un array de documentos obtenidos.
 * 
 * @throws Error - Lanza una excepción si ocurre un error durante la operación.
 */
  public getDocumentsByReferences(references: DocumentReference<DocumentData>[]): Observable<{ uid: string; data: DocumentData }[]> {
    // Crear un Observable para cada referencia de documento
    const documentObservables = references.map(ref => from(getDoc(ref)).pipe(
      map((docSnapshot) => {
        if (docSnapshot.exists()) {
          return { uid: docSnapshot.id, data: docSnapshot.data() };
        }
        return null; // Si el documento no existe, devolver null
      }),
      catchError(error => {
        console.error('Error al obtener el documento referenciado:', error);
        return of(null); // Devolver null en caso de error
      })
    ));

    // Combinar todos los Observables en uno solo
    return forkJoin(documentObservables).pipe(
      map(docs => docs.filter((doc): doc is { uid: string; data: DocumentData } => doc !== null)), // Filtrar los documentos que no son nulos
      catchError(this.handleError) // Manejo de errores
    );
  }

  /**
 * Actualiza un documento específico de la colección por su ID con los datos proporcionados.
 * @param documentId El ID del documento que se va a actualizar.
 * @param data Los datos actualizados del documento.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocument(documentId: string, data: any, collectionName: string): Observable<any> {
    return from(setDoc(doc(collection(this.firestore, collectionName), documentId), data)).pipe(
      switchMap(() => {
        return from(getDoc(doc(collection(this.firestore, collectionName), documentId))).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data();
              return { uid: id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error(
              'Error al obtener el documento después de la actualización:',
              error
            );
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error; // Propaga el error
      })
    );
  }

  /* 
    TODO Faltan por agregar muchos mas metodos
  */

  public performTransaction(transactionFunction: (transaction: Transaction) => Promise<void>): Observable<void> {
    return from(runTransaction(this.firestore, transactionFunction)).pipe(
      catchError((error) => {
        console.error('Error en la transacción:', error);
        return throwError(() => error);
      })
    );
  }


  public getCollectionRef(collectionName: string): CollectionReference<DocumentData> {
    return collection(this.firestore, collectionName);
  }

  public getSubCollectionRef(collectionName: string, docId: string, subCollectionName: string): CollectionReference<DocumentData> {
    return collection(this.firestore, collectionName, docId, subCollectionName);
  }

  /**
   * Maneja errores en los métodos que devuelven un Observable.
   * 
   * Este método se encarga de registrar el error en la consola y lanzar una nueva excepción con un mensaje genérico.
   * Es útil para manejar errores en operaciones asíncronas, como las peticiones a Firebase, y proporcionar un mensaje de error claro al usuario.
   * 
   * @param error - El error que ocurrió, puede ser cualquier tipo de error capturado en la operación asíncrona.
   * @returns Un Observable que nunca emite valores porque lanza una excepción en lugar de emitir datos.
   * 
   * @throws Error - Lanza una nueva excepción con un mensaje genérico para que el flujo de datos del observable sea interrumpido.
   * 
   * @example
   * ```typescript
   * this.firestoreService.getDocuments().pipe(
   *   catchError(this.handleError)
   * ).subscribe(
   *   data => console.log(data),
   *   error => console.error(error) // Aquí se captura la excepción lanzada por handleError
   * );
   * ```
   */
  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    throw new Error('Algo salió mal; por favor, intenta otra vez después.');
  }


}