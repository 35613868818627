import { Injectable } from '@angular/core';
import { Firestore, getFirestore } from '@angular/fire/firestore';

import { getDownloadURL, ref, Storage, uploadBytes } from '@angular/fire/storage';

import { collection, doc } from 'firebase/firestore';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FirestoreToolsService {

    private firestoreInstance: Firestore;

    constructor(private storage: Storage) {
        this.firestoreInstance = getFirestore();
    }

    generateUID(): string {
        const newDocRef = doc(collection(this.firestoreInstance, 'tempCollection'));
        return newDocRef.id;
    }

    getFileReferenceStorage(gCSURI: string): Observable<string> {
        return from(getDownloadURL(ref(this.storage, gCSURI)));
    }

    uploadFile(fileToUpload: File): Observable<any> {
        const storageRef = ref(this.storage, 'COMPANYIDHEREWITHVAR/' + fileToUpload.name);
        console.log(storageRef);
        
        return from(uploadBytes(storageRef, fileToUpload));
    }

    getReference(collectionName: string, documentId: string) {
        return doc(this.firestoreInstance, `${collectionName}/${documentId}`);
    }
}