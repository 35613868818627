import { Injectable } from '@angular/core';
import { FirebaseOptions, initializeApp, } from 'firebase/app';
import { environment } from '../../../../environments/environment'

import {
  BehaviorSubject, Observable, catchError,
  finalize, forkJoin, from, map, of, switchMap, tap, throwError,
} from 'rxjs';

import {
  addDoc,
  collection,
  getFirestore,
  query,
  DocumentReference,
  CollectionReference,
  Firestore,
  getDocs,
  DocumentData,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  QuerySnapshot,
  onSnapshot,
  where,
  updateDoc,
  limit
} from '@angular/fire/firestore';

import {
  ref,
  uploadBytes,
  getDownloadURL,
  Storage,
} from '@angular/fire/storage';

@Injectable({ providedIn: 'root' })
export class SharedService {
  // Instancia de Firestore
  private firestoreInstance: Firestore;
  // Referencia a la colección Firestore
  private collectionRef: CollectionReference<DocumentData>;


  constructor(private storage: Storage) {
    // Inicializa la instancia de Firestore
    this.firestoreInstance = getFirestore();

    const storedDocuTable = localStorage.getItem('docuTable');
    if (storedDocuTable !== null) {
      this.fillTable(JSON.parse(storedDocuTable))
    }

    const activeDocu = localStorage.getItem('activeDocu');
    if (activeDocu !== null) {
      this.shareActiveDocuId(JSON.parse(activeDocu))
    }

  }

  // Método para generar un UID único
  generateUID(): string {
    const newDocRef = doc(collection(this.firestoreInstance, 'tempCollection'));
    return newDocRef.id;
  }

  getFileReferenceStorage(gCSURI: string): Observable<string> {
    return from(getDownloadURL(ref(this.storage, gCSURI)));
  }

  uploadFile(fileToUpload: File): Observable<any> {
    const storageRef = ref(this.storage, 'COMPANYIDHEREWITHVAR/' + fileToUpload.name);
    return from(uploadBytes(storageRef, fileToUpload));
  }
  
  /**
   * Establece la colección en la que se realizarán las operaciones.
   * @param collectionName El nombre de la colección en Firebase Firestore.
   */
  setCollection(collectionName: string): void {
    this.collectionRef = collection(this.firestoreInstance, collectionName);
  }


  /**
 * Lee todos los documentos de la colección actual y actualiza el flujo de datos observable.
 * @returns Observable que emite un array de documentos de la colección actual.
 */
  public readDocuments<T>(): Observable<T[]> {
    const q = query(this.collectionRef);
    return new Observable<T[]>((observer) => {
      const unsubscribe = onSnapshot(
        q, (querySnapshot: QuerySnapshot<DocumentData>) => {
          const result: T[] = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data() as T;
            result.push({ uid:id, ...data });
          });
          observer.next(result);
        }, (error) => {
          observer.error(error);
        });

      return () => unsubscribe();
    }).pipe(
      catchError(this.handleError)
    );
  }

  /**
* Lee todos los documentos de la colección que se manda como parametro
* @param collectionName Nombre de la collection con la que se va a trabajar
* @returns Observable que emite un array de documentos de la colección actual.
*/
  public readDocumentsWithCollection<T>(collectionName: string): Observable<T[]> {
    const collectionRefUpdate = collection(this.firestoreInstance, collectionName);
    const q = query(collectionRefUpdate);
    return new Observable<T[]>((observer) => {
      const unsubscribe = onSnapshot(
        q, (querySnapshot: QuerySnapshot<DocumentData>) => {
          const result: T[] = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data() as T;
            result.push({ uid:id, ...data });
          });
          observer.next(result);
        }, (error) => {
          observer.error(error);
        });

      return () => unsubscribe();
    }).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Lee todos los documentos de la colección actual utilizando un enfoque
   * deprecado y actualiza el flujo de datos observable.
   * @deprecated Usa readDocuments en su lugar.
   * @returns Observable que emite un array de documentos de la colección actual.
   */
  public readDocumentsDeprecated<T>(): Observable<T[]> {
    const q = query(this.collectionRef);
    return from(getDocs(q)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data() as T;
          return { uid:id, ...data };
        })
      ),
      catchError(this.handleError)
    );
  }

  /**
   * Lee todos los documentos de la colección actual utilizando un enfoque
   * deprecado y actualiza el flujo de datos observable.
   * @param collectionName Nombre de la collection con la que se va a trabajar
   * @deprecated Usa readDocuments en su lugar.
   * @returns Observable que emite un array de documentos de la colección actual.
   */
  public readDocumentsDeprecatedWithCollection<T>(collectionName: string): Observable<T[]> {
    const collectionRefUpdate = collection(this.firestoreInstance, collectionName);
    const q = query(collectionRefUpdate);
    return from(getDocs(q)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const id = doc.id;
          const data = doc.data() as T;
          return { uid:id, ...data };
        })
      ),
      catchError(this.handleError)
    );
  }


  /**
   * Crea un nuevo documento en la colección con los datos proporcionados.
   * @param data Los datos del documento que se va a crear.
   * @returns Observable que emite el documento creado.
   */
  createDocument<T>(data: any): Observable<T> {
    return from(addDoc(this.collectionRef, data)).pipe(
      switchMap((docRef) => {
        return from(getDoc(docRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid:id, ...docData };
            }
            throw new Error('El documento creado no existe');
          }),
          catchError((error) => {
            console.error('Error al obtener el documento creado:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al crear el documento:', error);
        throw error;
      })
    );
  }

  // Método para crear un nuevo documento en la colección con los datos proporcionados
  public createDocumentWithCollection<T>(data: any, collectionName: string): Observable<T> {
    const collectionRefUpdate = collection(this.firestoreInstance, collectionName);
    return from(addDoc(collectionRefUpdate, data)).pipe(
      switchMap((docRef) => {
        return from(getDoc(docRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid: id, ...docData };
            }
            throw new Error('El documento creado no existe');
          }),
          catchError((error) => {
            console.error('Error al obtener el documento creado:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al crear el documento:', error);
        throw error;
      })
    );
  }


  createDocumentWithId<T>(id: string, data: any): Observable<T> {
    const docRef = doc(this.collectionRef, id);
    return from(setDoc(docRef, data)).pipe(
      switchMap(() => {
        return from(getDoc(docRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid:id, ...docData };
            }
            throw new Error('El documento creado no existe');
          }),
          catchError((error) => {
            console.error('Error al obtener el documento creado:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al crear el documento:', error);
        throw error;
      })
    );
  }

  public createDocumentWithIdWithCollection<T>(id: string, data: any, collectionName: string): Observable<T> {
    const docRef = doc(collection(this.firestoreInstance, collectionName), id);
    return from(setDoc(docRef, data)).pipe(
      switchMap(() => {
        return from(getDoc(docRef)).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid:id, ...docData };
            }
            throw new Error('El documento creado no existe');
          }),
          catchError((error) => {
            console.error('Error al obtener el documento creado:', error);
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al crear el documento:', error);
        throw error;
      })
    );
  }

  /**
   * Obtiene un documento específico de la colección por su ID.
   * @param documentId El ID del documento que se va a obtener.
   * @returns Observable que emite el documento obtenido.
   */
  getDocumentById<T>(documentId: string): Observable<T> {
    const docRef = doc(this.collectionRef, documentId);
    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        const id = docSnapshot.id;
        const data = docSnapshot.data() as T;
        return { uid:id, ...data };
      }),
      catchError(this.handleError)
    );
  }

  public getDocumentByIdWithCollection<T>(documentId: string, collectionName: string): Observable<T> {
    const docRef = doc(collection(this.firestoreInstance, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => {
        const id = docSnapshot.id;
        const data = docSnapshot.data() as T;
        return { uid:id, ...data };
      }),
      catchError(this.handleError)
    );
  }

 // Método para obtener documentos a partir de un arreglo de DocumentReference
 public getDocumentsByReferences<T>(references: DocumentReference<DocumentData>[]): Observable<T[]> {
  const documentObservables = references.map(ref => from(getDoc(ref)).pipe(
    map((docSnapshot) => {
      return docSnapshot.exists() ? ({ uid: docSnapshot.id, ...docSnapshot.data() } as T) : null;
    }),
    catchError(error => {
      console.error('Error al obtener el documento referenciado:', error);
      return of(null); // Devolver null en caso de error
    })
  ));

  return forkJoin(documentObservables).pipe(
    map(docs => docs.filter((doc): doc is T => doc !== null)), // Filtrar los documentos que no son nulos
    catchError(this.handleError)
  );
}
  /**
   * Elimina un documento de la colección especificada.
   * @param documentId El ID del documento que se desea eliminar.
   * @returns Un observable que emite un mensaje de éxito cuando el documento se elimina correctamente,
   * o un mensaje de error si no se encuentra el documento o si ocurre un error durante el proceso de eliminación.
   */
  deleteDocument(documentId: string): Observable<string> {
    const docRef = doc(this.collectionRef, documentId);

    return from(getDoc(docRef)).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists()) {
          return from(deleteDoc(docSnapshot.ref)).pipe(
            switchMap(() => {
              return of(`Se ha eliminado el documento con ID: ${documentId}`);
            })
          );
        } else {
          return throwError(
            () =>
              new Error(
                `No se encontró ningún documento con el ID: ${documentId}`
              )
          );
        }
      }),
      catchError((error) => {
        console.error('Error al intentar eliminar el documento:', error);
        throw new Error('Error al intentar eliminar el documento');
      })
    );
  }
  /**
 * Elimina un documento de la colección especificada.
 * @param documentId El ID del documento que se desea eliminar.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Un observable que emite un mensaje de éxito cuando el documento se elimina correctamente,
 * o un mensaje de error si no se encuentra el documento o si ocurre un error durante el proceso de eliminación.
 */
  public deleteDocumentWithCollection(documentId: string, collectionName: string): Observable<string> {
    const docRef = doc(collection(this.firestoreInstance, collectionName), documentId);

    return from(getDoc(docRef)).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists()) {
          return from(deleteDoc(docSnapshot.ref)).pipe(
            switchMap(() => {
              return of(`Se ha eliminado el documento con ID: ${documentId}`);
            })
          );
        } else {
          return throwError(
            () =>
              new Error(
                `No se encontró ningún documento con el ID: ${documentId}`
              )
          );
        }
      }),
      catchError((error) => {
        console.error('Error al intentar eliminar el documento:', error);
        throw new Error('Error al intentar eliminar el documento');
      })
    );
  }




  /**
   * Elimina todos los documentos de la colección actual.
   * @returns Un observable que emite un valor booleano indicando el éxito de la operación.
   * En caso de que la colección esté vacía o no exista, el observable emitirá un error.
   */
  deleteAllDocuments(): Observable<boolean> {
    // Obtener una instantánea de la colección
    return from(getDocs(this.collectionRef)).pipe(
      map((querySnapshot) => {
        // Verificar si la colección tiene algún documento
        if (querySnapshot.size === 0) {
          throw new Error(
            'La colección está vacía, no hay documentos para eliminar.'
          );
        } else {
          // Obtener la referencia de la colección
          const collectionRef = collection(
            this.collectionRef.firestore,
            this.collectionRef.path
          );
          return collectionRef;
        }
      }),
      switchMap((collectionRef) => {
        // Obtener todos los documentos de la colección
        return from(getDocs(collectionRef)).pipe(
          map((querySnapshot) => querySnapshot.docs.map((doc) => doc.ref)),
          tap((docRefs) => {
            // Eliminar cada documento de la colección
            docRefs.forEach((docRef) => {
              deleteDoc(docRef);
            });
          }),
          map(() => true),
          catchError((error) => {
            console.error(
              'Error al intentar eliminar todos los documentos:',
              error
            );
            return throwError(
              () => 'Error al intentar eliminar todos los documentos'
            );
          })
        );
      })
    );
  }

  /**
 * Elimina todos los documentos de la colección actual.
 * @param collectionName El nombre de la colección que se desea vaciar.
 * @returns Un observable que emite un valor booleano indicando el éxito de la operación.
 * En caso de que la colección esté vacía o no exista, el observable emitirá un error.
 */
  public deleteAllDocumentsWithCollection(collectionName: string): Observable<boolean> {
    // Obtener una instantánea de la colección
    return from(getDocs(collection(this.firestoreInstance, collectionName))).pipe(
      switchMap((querySnapshot) => {
        // Verificar si la colección tiene algún documento
        if (querySnapshot.size === 0) {
          throw new Error(
            'La colección está vacía, no hay documentos para eliminar.'
          );
        } else {
          // Obtener la referencia de la colección
          const collectionRef = collection(this.firestoreInstance, collectionName);
          return from(getDocs(collectionRef)).pipe(
            map((querySnapshot) => querySnapshot.docs.map((doc) => doc.ref)),
            tap((docRefs) => {
              // Eliminar cada documento de la colección
              docRefs.forEach((docRef) => {
                deleteDoc(docRef);
              });
            }),
            map(() => true),
            catchError((error) => {
              console.error(
                'Error al intentar eliminar todos los documentos:',
                error
              );
              return throwError(
                () => 'Error al intentar eliminar todos los documentos'
              );
            })
          );
        }
      })
    );
  }

  /**
   * Actualiza un documento específico de la colección por su ID con los datos proporcionados.
   * @param documentId El ID del documento que se va a actualizar.
   * @param data Los datos actualizados del documento.
   * @returns Observable que emite el documento actualizado.
   */
  updateDocument<T>(documentId: string, data: any): Observable<T | null> {
    return from(setDoc(doc(this.collectionRef, documentId), data)).pipe(
      switchMap(() => {
        return from(getDoc(doc(this.collectionRef, documentId))).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid:id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error(
              'Error al obtener el documento después de la actualización:',
              error
            );
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error; // Propaga el error
      })
    );
  }

  getDocReference(collectionNameToModify: string,
    documentIdToModify: string){
    return doc(this.firestoreInstance, `${collectionNameToModify}/${documentIdToModify}`);
  }
  getNewDocReference(collectionNameRefToAdd: string,
    refIdToAdd: string){
    return doc(this.firestoreInstance, `${collectionNameRefToAdd}/${refIdToAdd}`);
  }
  

  public updateDocumentWithReference(docRef:DocumentReference,
    newDocRef:DocumentReference,
    field: string
  ): Observable<void> {

    return from(getDoc(docRef)).pipe(
      switchMap((docSnapshot) => {
        if (docSnapshot.exists()) {
          const existingRefs = docSnapshot.get(field) || [];
          const updatedRefs = [...existingRefs, newDocRef];
          const updateData = { [field]: updatedRefs }; // Use field as the dynamic key

          return from(updateDoc(docRef, updateData));
        } else {
          throw new Error('El documento no existe');
        }
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error // Re-throw the error to be handled by the caller
      })
    );
  }

  /**
 * Actualiza un documento específico de la colección por su ID con los datos proporcionados.
 * @param documentId El ID del documento que se va a actualizar.
 * @param data Los datos actualizados del documento.
 * @param collectionName El nombre de la colección donde se encuentra el documento.
 * @returns Observable que emite el documento actualizado.
 */
  public updateDocumentWithCollection<T>(documentId: string, data: any, collectionName: string): Observable<T | null> {
    return from(setDoc(doc(collection(this.firestoreInstance, collectionName), documentId), data)).pipe(
      switchMap(() => {
        return from(getDoc(doc(collection(this.firestoreInstance, collectionName), documentId))).pipe(
          map((docSnapshot) => {
            if (docSnapshot.exists()) {
              const id = docSnapshot.id;
              const docData = docSnapshot.data() as T;
              return { uid:id, ...docData };
            }
            return null;
          }),
          catchError((error) => {
            console.error(
              'Error al obtener el documento después de la actualización:',
              error
            );
            throw error;
          })
        );
      }),
      catchError((error) => {
        console.error('Error al actualizar el documento:', error);
        throw error; // Propaga el error
      })
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('Error:', error);
    throw new Error('Algo salió mal; por favor, intenta otra vez después.');
  }


  //Data del docuType

  private idSource = new BehaviorSubject<any>(0);
  currentData = this.idSource.asObservable();

  showid(data: object, id: any) {
    const zip = { data: data, id: id, };
    this.idSource.next(zip);
    console.log("zip: ", zip)
  }

  //ID del WS


  private docuUserDataSubject = new BehaviorSubject<any>(null);
  docuUserObs = this.docuUserDataSubject.asObservable();

  updateDocuUserData(data: any) {
    this.docuUserDataSubject.next(data);
  }

  /**
   * Servicio para leer los documentos buscando si existe uno con el mismo "WSID" y el mismo "docuID"
   * @param WSID El ID de la collección contenedora de DT
   * @param docuID El ID del DT 
   * @returns true o false en caso de existir
   */

  FIREBASECONFIG: FirebaseOptions = environment.firebaseConfig;
  app = initializeApp(this.FIREBASECONFIG);

  async getDocsWithMatchingValues(wsId: string, dtId: string) {
    console.log(wsId)
    console.log(dtId)

    const db = getFirestore(this.app);
    const registrosTestRef = collection(db, "registrosTest");

    try {
      const q = query(registrosTestRef, where('wsID', '==', wsId));

      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        console.log(doc);
      });
    } catch (error) {
      console.error("Error obteniendo documentos:", error);
    }

  }

  /**
     * Busca documentos que coincidan con los valores especificados para 'wsID' y 'docuID'.
     * @param wsId El ID de la colección contenedora de DT.
     * @param dtId El ID del DT.
     * @returns Un observable que emite un array de documentos que coinciden con los valores especificados.
     */
  getDocsWithMatchingValuesObservable(wsId: string, dtId: number): Observable<any[]> {
    return new Observable<any[]>((observer) => {
      const db = getFirestore(this.app);
      const registrosTestRef = collection(db, "registrosTest");

      try {
        const q = query(registrosTestRef, where('wsID', '==', wsId), where('docuID', '==', dtId));

        const unsubscribe = onSnapshot(q, (querySnapshot: QuerySnapshot<DocumentData>) => {
          const result: any[] = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            result.push({ id, ...data });
          });
          observer.next(result);
        }, (error) => {
          observer.error(error);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error obteniendo documentos:", error);
        observer.error(error);
      }
    }).pipe(
      catchError((error) => {
        console.error("Error obteniendo documentos:", error);
        throw error;
      })
    );
  }



  /* Llenar data tablas */

  private dataTable = new BehaviorSubject<any>(null);
  dataTableObs = this.dataTable.asObservable();

  fillTable(data: any) {
    this.dataTable.next(data);
  }


  /* Traer registros */
  async getActiveDocus(colId: any, subId: any, docuId: any, maxitem: number) {
    try {

      const db = getFirestore(this.app);
      const colRef = collection(db, "sasWaCo4rAdr8ylCre19p0");

      const q = query(colRef,
        where("collectionId", "==", colId),
        where("subCollectionId", "==", subId),
        where("docuTypeId", "==", docuId),
        limit(maxitem)
      );

      const querySnapshot = await getDocs(q);
      const allData: any[] = [];

      querySnapshot.docs.forEach((doc) => {
        const data = doc.data().data;
        const id = doc.id;
        allData.push({ id, data });
      });

      return allData;

    } catch (error) {

      console.error("Error", error);
      return [];

    }
  }
  async getActiveDocusFilter(colId: any, subId: any, docuId: any, columna: any, match: any) {
    try {

      const db = getFirestore(this.app);
      const colRef = collection(db, "sasWaCo4rAdr8ylCre19p0");

      const q = query(colRef,
        where("collectionId", "==", colId),
        where("subCollectionId", "==", subId),
        where("docuTypeId", "==", docuId),
        /* where(columna, "==", match), */
      );

      const querySnapshot = await getDocs(q);
      const allData: any[] = [];

      console.log(querySnapshot.docs)

      querySnapshot.docs.forEach((doc) => {
        const data = doc.data().data;
        const id = doc.id;
        allData.push({ id, data });
      });

      return allData;

    } catch (error) {

      console.error("Error", error);
      return [];

    }
  }

  /* Pasar el id del active docu al componente visor */
  activeID = new BehaviorSubject<any | undefined>(undefined);

  shareActiveDocuId(data: any) {
    if (typeof data.id === 'string' || data.id === undefined) {
      this.activeID.next(data);
    } else {
      console.warn('No es un id valido', data.id);
    }
  }

  async activeDocu(id: any) {
    const db = getFirestore(this.app);
    const docRef = doc(db, "sasWaCo4rAdr8ylCre19p0", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data()
    } else {
      console.log("No hay documento");
    }
  }

  async updateActiveDocu(id: any, newData: any) {
    const db = getFirestore(this.app);
    const docRef = doc(db, "sasWaCo4rAdr8ylCre19p0", id);
    const docSnap = await getDoc(docRef);

    const OldData = docSnap.data()?.data;
    const NewData = newData;

    const update = { ...OldData, ...NewData };

    await updateDoc(docRef, {
      "data": update
    });
  }


}
