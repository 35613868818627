import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { provideAppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider} from '@angular/fire/app-check';
import { provideAuth, initializeAuth, indexedDBLocalPersistence, browserPopupRedirectResolver } from '@angular/fire/auth';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage} from '@angular/fire/storage'
import { getDatabase, provideDatabase } from '@angular/fire/database'
import { getFunctions, provideFunctions } from '@angular/fire/functions';

import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';

import { InlineSVGModule } from 'ng-inline-svg-2';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { VisorActiveDocuComponent } from './modules/visor-active-docu/visor-active-docu.component';


@NgModule({
  declarations: [AppComponent, VisorActiveDocuComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAppCheck(() => initializeAppCheck(getApp(), {
      provider: new ReCaptchaEnterpriseProvider(environment.reCaptchaEnterpriseProvider.keyId),
      isTokenAutoRefreshEnabled: true,
    })),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver,
      });
      return auth;
    }),
    provideFirestore(() => getFirestore(getApp())),
    provideStorage(() => getStorage(getApp())),
    provideFunctions(() => getFunctions(getApp(), environment.firebaseConfig.functionsRegion)),
    provideDatabase(() => getDatabase(getApp())),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
