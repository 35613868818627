// France
export const locale = {
  lang: 'fr',
  data: {
    TRANSLATOR: {
      SELECT: 'choisissez votre langue',
    },
    MENU: {
      NEW: 'Nouveau',
      ACTIONS: 'Actes',
      CREATE_POST: 'Créer un nouveau Post',
      PAGES: 'Pages',
      FEATURES: 'Fonctionnalités',
      APPS: 'Applications',
      DASHBOARD: 'Tableau de Bord',
      WORKSPACES: 'Espaces de travail',
      WS_COLLECTION: 'Collections',
      HOME: 'Hub',
      HUBSETTINGS: 'Paramètres du Hub'
    },
    USER:{
      USER: 'Utilisateur',
      MYUSER: 'Mon utilisateur',
      TABS: {
        CURRENT: 'Actuel',
        SETTINGS: 'Paramètres',
        PREFERENCES: 'Préférences'
      },
      SECTIONNAMES: {
        CURRENTINFO: "Informations sur l'utilisateur actuel"
      },
      FIELDSFORMS: {
        UPDATEPIC: 'Mettez à jour votre photo de profil',
      },
      HELPTEXTS: {
        WRITEYOURNAME: 'Écrivez ici votre nom',
        WRITEYOURLASTNAME: 'Écrivez ici votre nom de famille',
        WRITEUSERNAME: '@créezvotresuperutilisateur',
        WRITEAWESOMEUSER: "Écrivez un nom d'utilisateur génial et unique.",
        SELECTCOUNTRY: 'Choisissez un pays:',
        SELECTLANGUAGE: 'Sélectionnez une langue:',
        SELECTTIMEZONE: 'Sélectionnez un fuseau horaire:',
        SELECTCURRENCY: 'Sélectionnez une devise:',
        NOTELANGUAGEDEFAULT: 'La langue que vous sélectionnez sera définie par défaut.',
      },
      GLOBALTEXTS: {
        ACTIVEUSER: 'Utilisateur actif',
        INACTIVEUSER: 'Utilisateur inactif'
      }
    },
    HUB: {
      BUTTONACTIVATEHUB: 'Activer le Hub',
      CURRENTHUB: 'Hub Actuel',
      ALLHUBS: 'Tous les hubs',
      BILLING: 'Facturation',
      SECTIONNAMES: {
        HUBINFO: 'Informations sur le Hub',
        DEACTIVATEHUB: 'Désactiver le Hub',
        ALLHUBS: 'Tous les Hubs',
      },
      FIELDSFORMS: {
        UPDATELOGO: 'Mettre à jour le logo du HUB',
        COUNTRYOFOPERATION: "Pays d'opération:",
      },
      HELPTEXTS: {
        SELECTCOUNTRY: "Sélectionnez le pays d'opération:",
        DESCRIPTIONHELP: "Écrivez ici pourquoi ou dans quel but ce Hub existe.",
        WRITENAME: 'Écrivez un nom descriptif.',
        ALLHUBSWITHACCESS: 'Tous les hubs auxquels vous avez accès',
      },
      GLOBALTEXTS: {
        YOURAREDEACTIVATING: 'Vous désactivez le Hub {{hubName}}',
        DEACTIVATINGMESSAGE: "N'oubliez pas qu'en désactivant ce Hub, aucun utilisateur ne pourra y accéder ni aux collections et documents créés.",
        CONFIRMDEACTIVATION: 'Je confirme la désactivation du Hub',
        CONFIRMCHECKDEACTIVATE: 'Veuillez cocher la case pour désactiver ce Hub',
        NEWHUB: 'Nouveau Hub',
      },
    },
    COLLECTIONS: {
      NAME: 'Collectes',
    },
    ACTIVEDOCUS: {
      NAME: 'Docu Actif',
    },
    AUTH: {
      GENERAL: {
        OR: 'Ou',
        SUBMIT_BUTTON: 'Soumettre',
        NO_ACCOUNT: 'Ne pas avoir de compte?',
        SIGNUP_BUTTON: 'Registre',
        FORGOT_BUTTON: 'Mot de passe oublié',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Créer un compte',
        BUTTON: 'Sign In',
        LOGOUT: 'Se déconnecter',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Mot de passe',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Nom d\'utilisateur'
      },
      VALIDATION: {
        INVALID: '{{name}} n\'est pas valide',
        REQUIRED: '{{name}} est requis',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Nombre d\'enregistrements sélectionnés: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'éCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Les clients',
        CUSTOMERS_LIST: 'Liste des clients',
        NEW_CUSTOMER: 'Nouveau client',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Suppression du client',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement ce client?',
          WAIT_DESCRIPTION: 'Le client est en train de supprimer ...',
          MESSAGE: 'Le client a été supprimé'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Supprimer les clients',
          DESCRIPTION: 'Êtes-vous sûr de supprimer définitivement les clients sélectionnés?',
          WAIT_DESCRIPTION: 'Les clients suppriment ...',
          MESSAGE: 'Les clients sélectionnés ont été supprimés'
        },
        UPDATE_STATUS: {
          TITLE: 'Le statut a été mis à jour pour les clients sélectionnés',
          MESSAGE: 'Le statut des clients sélectionnés a été mis à jour avec succès'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Le client a été mis à jour',
          ADD_MESSAGE: 'Le client a été créé'
        }
      }
    },
    MEMBERS: {
      MEMBERS: 'Membres',
      THISUSERALREDYSELECTED: 'Cet utilisateur "{{keyword}}" est déjà sélectionné.',
      SHOWINGRESULTSOF: 'Affichage des résultats pour "{{keyword}}"',
      PLACEHOLDERSEARCHMEMBERS: "Trouver un utilisateur par email ou nom d'utilisateur comme (@docuusername)",
      SELECTEDUSERS: 'Utilisateurs sélectionnés',
      ROLEPERMISSION: 'Permissions de Rôle',
    },
    GLOBALTEXTS: {
      HELLO: 'Bonjour',
      PLEASEFILLINFO: "¡Hé !, Veuillez remplir vos informations d'utilisateur...",
      ACTIVE: 'Actif',
      DISABLED: 'Désactivé',
      LANGUAGE: 'Langue',
      NAME: 'Nom',
      LASTNAME: 'Nom de famille',
      ADDRESS: 'Adresse',
      WEBSITE: 'Site Web',
      COUNTRY: 'Pays',
      TIMEZONE: 'Fuseau Horaire',
      CURRENCY: 'Devise',
      DOCUUSERNAME: "Nom d'utilisateur",
      SAVE: 'Sauvegarder',
      PLEASEWAIT: "S'il vous plaît, attendez",
      GENERALDESCRIPTION: 'Description Générale',
      KNOWMORE: 'En savoir plus',
      DEACTIVATE: 'Désactiver',
      MANAGEMEMBERS: 'ManageGérer les Membres',
      FINISH: 'Finir',
      MEMBERSOF: 'Membres de',
      CLOSE: 'Fermer',
      SEARCHNOTFOUND: 'Aucun résultat trouvé pour la recherche : "{{keyword}}"',
      SELECT: 'Sélectionner',
      REMOVE: 'Supprimer',
      SHARENOW: 'Partager Maintenant',
      WITHACCESS: 'Avec accès',
    }
  }
};
