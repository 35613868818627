import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { FirestoreToolsService } from 'src/app/core/services/firestore-tools.service';

@Injectable({ providedIn: 'root' })
export class ImageUploaderService implements OnDestroy {
    private unsubscribe$ = new Subject<void>();

    private imageSubject: BehaviorSubject<File | null> = new BehaviorSubject<File | null>(null)


    constructor(
        private fstSvc: FirestoreToolsService
    ) { }

    getImage() { return this.imageSubject.asObservable() }
    setImage(file: File | null) { this.imageSubject.next(file) }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    uploadField(imagenLogo: File | null): Observable<any | null> {
        if (imagenLogo) {
            return this.fstSvc.uploadFile(imagenLogo).pipe(
                takeUntil(this.unsubscribe$), // Para manejar la cancelación de la suscripción
                catchError((error) => {
                    console.error('Error uploading file or getting URL', error);
                    return of(null); // En caso de error, retorna null
                })
            );
        }
        return of(null)
    }

    getUrlFile(gCSURI: string): Observable<string | null> {
        return this.fstSvc.getFileReferenceStorage(gCSURI).pipe(
            catchError((error) => {
                console.error('Error getting file or getting URL', error);
                return of(null); // En caso de error, retorna null
            })
        )
    }

    getImageSelected(): File | null {
        if (this.imageSubject.value) {
            return this.imageSubject.value
        }else{
            return null
        }
    }
}