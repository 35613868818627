import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class FireStoreCollectionsService {
    constructor() { }
    
    get usersCollection(){
        return environment.docuworksConfig.tables.users;
    }

    get hubCollection(){
        return environment.docuworksConfig.tables.docuHubs;
    }

    get workspaceCollection(){
        return environment.docuworksConfig.tables.workSpaces;
    }
    
    get docuHubsCollection(){
        return environment.docuworksConfig.tables.docuHubs;
    }
    get docuDocuTypeCollection(){
        return environment.docuworksConfig.tables.docutypes;
    }
    get activeDocuCollection(){
        return environment.docuworksConfig.tables.activeDocus;
    }
    get worldUsers(){
        return environment.docuworksConfig.tables.worldUsers
    }

    get profilesCollection(){
        return environment.docuworksConfig.tables.profiles
    }

}