import { Component } from '@angular/core';

@Component({
  selector: 'app-no-email-verified',
  templateUrl: './no-email-verified.component.html',
  styleUrls: ['./no-email-verified.component.scss']
})
export class NoEmailVerifiedComponent {

}
