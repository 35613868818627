import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { canActivate, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { NoEmailVerifiedComponent } from '@pages/no-email-verified/no-email-verified.component';
import { EmailVerifiedGuard } from '@pages/guards/emailVerified.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { NoUserAuthGuard } from './auth/guards/no-user-auth.guard';

const redirectUnauthorized = () => {
  return redirectUnauthorizedTo(['/auth/login']);
};

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoUserAuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'noemailverified',
    component: NoEmailVerifiedComponent,
  },
  {
    path: 'auth1',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'metronic/layout',
    ...canActivate(redirectUnauthorized),
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: '', 
    redirectTo: 'pages', 
    pathMatch: 'full'
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
