// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      WORKSPACES: 'Workspaces',
      WS_COLLECTION: 'Collections',
      HOME: 'Hub',
      HUBSETTINGS: 'Hub Settings'
    },
    USER:{
      USER: 'User',
      MYUSER: 'My user',
      TABS: {
        CURRENT: 'Current',
        SETTINGS: 'Settings',
        PREFERENCES: 'Preferences',
      },
      SECTIONNAMES: {
        CURRENTINFO: 'Current User Information',
      },
      FIELDSFORMS: {
        UPDATEPIC: 'Update your profile picture'
      },
      HELPTEXTS: {
        WRITEYOURNAME: 'Write here your name',
        WRITEYOURLASTNAME: 'Write here your last name',
        WRITEUSERNAME: '@createyourawsomeuser',
        WRITEAWESOMEUSER: 'Write an awesome and unique username.',
        SELECTCOUNTRY: 'Select a Country:',
        SELECTLANGUAGE: 'Select a Language:',
        SELECTTIMEZONE: 'Select a Time Zone:',
        SELECTCURRENCY: 'Select a Currency:',
        NOTELANGUAGEDEFAULT: 'The language you select will be set as default.',
      },
      GLOBALTEXTS: {
        ACTIVEUSER: 'Active User',
        INACTIVEUSER: 'Inactive User',
      }
    },
    HUB: {
      BUTTONACTIVATEHUB: 'Activate Hub',
      CURRENTHUB: 'Current Hub',
      ALLHUBS: 'All Hubs',
      BILLING: 'Billing',
      SECTIONNAMES: {
        HUBINFO: 'Hub Information',
        DEACTIVATEHUB: 'Deactivate Hub',
        ALLHUBS: 'All Hubs',
      },
      FIELDSFORMS: {
        UPDATELOGO: 'Update Hub logo',
        COUNTRYOFOPERATION: 'Country of operation:',
      },
      HELPTEXTS: {
        SELECTCOUNTRY: 'Select the country of operation:',
        DESCRIPTIONHELP: "Write here the reason or purpose for this Hub's existence.",
        WRITENAME: 'Write a descriptive name.',
        ALLHUBSWITHACCESS: 'All hubs you have access to',
      },
      GLOBALTEXTS: {
        YOURAREDEACTIVATING: "You Are Deactivating the Hub {{hubName}}",
        DEACTIVATINGMESSAGE: 'Remember that when deactivating this Hub, no user will be able to access it or the collections and documents created.',
        CONFIRMDEACTIVATION: 'I confirm the Hub deactivation',
        CONFIRMCHECKDEACTIVATE: 'Please check the box to deactivate this hub',
        NEWHUB: 'New Hub',
      },
    },
    COLLECTIONS: {
      NAME: 'Collections',
    },
    ACTIVEDOCUS: {
      NAME: 'Active Docus',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
        LOGOUT: 'Sign Out'
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    MEMBERS: {
      MEMBERS: 'Members',
      THISUSERALREDYSELECTED: 'This user "{{keyword}}" is already selected.',
      SHOWINGRESULTSOF: 'Showing results for "{{keyword}}"',
      PLACEHOLDERSEARCHMEMBERS: 'Find a user by email or username like (@docuusername)',
      SELECTEDUSERS: 'Selected Users',
      ROLEPERMISSION: 'Role Permissions',
    },
    GLOBALTEXTS: {
      HELLO: 'Hello',
      PLEASEFILLINFO: '¡Hey!, Please fill your user information...',
      ACTIVE: 'Active',
      DISABLED: 'Disabled',
      LANGUAGE: 'Language',
      NAME: 'Name',
      LASTNAME: 'Lastname',
      ADDRESS: 'Address',
      WEBSITE: 'Website',
      COUNTRY: 'Country',
      TIMEZONE: 'Time Zone',
      CURRENCY: 'Currency',
      DOCUUSERNAME: 'Docu User Name',
      SAVE: 'Save',
      PLEASEWAIT: 'Please wait',
      GENERALDESCRIPTION: 'General Description',
      KNOWMORE: 'Learn more',
      DEACTIVATE: 'Deactivate',
      MANAGEMEMBERS: 'Manage Members',
      FINISH: 'Finish',
      MEMBERSOF: 'Members of',
      CLOSE: 'Close',
      SEARCHNOTFOUND: 'No results found for search: "{{keyword}}"',
      SELECT: 'Select',
      REMOVE: 'Remove',
      SHARENOW: 'Share Now',
      WITHACCESS: 'With Access',
    }
  }
};
