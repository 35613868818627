// México We <3
export const locale = {
  lang: 'es',
  data: {
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
    },
    MENU: {
      NEW: 'nuevo',
      ACTIONS: 'Comportamiento',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Pages',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero',
      WORKSPACES: 'Espacios de Trabajo',
      WS_COLLECTION: 'Colecciones',
      HOME: 'Hub',
      HUBSETTINGS: 'Configuraciones del Hub',
    },
    USER:{
      USER: 'Usuario',
      MYUSER: 'Mi usuario',
      TABS: {
        CURRENT: 'Actual',
        SETTINGS: 'Configuraciones',
        PREFERENCES: 'Preferencias',
      },
      SECTIONNAMES: {
        CURRENTINFO: 'Información de tu usuario',
      },
      FIELDSFORMS: {
        UPDATEPIC: 'Actualiza tu foto de perfil',
      },
      HELPTEXTS: {
        WRITEYOURNAME: 'Escribe aquí tu nombre',
        WRITEYOURLASTNAME: 'Escribe aquí tus apellidos',
        WRITEUSERNAME: '@creaunusuariochingon',
        WRITEAWESOMEUSER: 'Escribe un nombre de usuario único.',
        SELECTCOUNTRY: 'Selecciona un país:',
        SELECTLANGUAGE: 'Selecciona un idioma:',
        SELECTTIMEZONE: 'Selecciona una zona horaria:',
        SELECTCURRENCY: 'Selecciona una moneda:',
        NOTELANGUAGEDEFAULT: 'El idioma que elijas se establecerá cómo predeterminado.',
      },
      GLOBALTEXTS: {
        ACTIVEUSER: 'Usuario Activo',
        INACTIVEUSER: 'Usuario Inactivo',
      }
    },
    HUB: {
      BUTTONACTIVATEHUB: 'Activar Hub',
      CURRENTHUB: 'Hub Actual',
      ALLHUBS: 'Todos los Hubs',
      BILLING: 'Facturación',
      SECTIONNAMES: {
        HUBINFO: 'Información del Hub',
        DEACTIVATEHUB: 'Desactivar Hub',
        ALLHUBS: 'Todos los Hubs',
      },
      FIELDSFORMS: {
        UPDATELOGO: 'Actualiza el logotipo del Hub',
        COUNTRYOFOPERATION: 'País de operación:',
      },
      HELPTEXTS: {
        SELECTCOUNTRY: 'Selecciona el país de operación:',
        DESCRIPTIONHELP: "Escribe aquí el ¿Por qué, o para qué? existe este Hub.",
        WRITENAME: 'Escribe un nombre descriptivo.',
        ALLHUBSWITHACCESS: 'Todos los hubs a los que tienes acceso',
      },
      GLOBALTEXTS: {
        YOURAREDEACTIVATING: 'Estás desactivando el Hub {{hubName}}',
        DEACTIVATINGMESSAGE: 'Recuerda que al desactivar este Hub ningún usuario podrá acceder a él ni a las colecciones y documentos creados.',
        CONFIRMDEACTIVATION: 'Confirmo la desactivación del Hub',
        CONFIRMCHECKDEACTIVATE: 'Por favor, marca la casilla para desactivar este Hub',
        NEWHUB: 'Nuevo Hub',
      },
    },
    COLLECTIONS: {
      NAME: 'Colecciones',
    },
    ACTIVEDOCUS: {
      NAME: 'Docus Activos',
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Espalda',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Registrarse',
        LOGOUT: 'Salir',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Usuario'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    },
    MEMBERS: {
      MEMBERS: 'Miembros',
      THISUSERALREDYSELECTED: 'Este usuario "{{keyword}}" ya está seleccionado.',
      SHOWINGRESULTSOF: 'Mostrando resultados de "{{keyword}}":',
      PLACEHOLDERSEARCHMEMBERS: 'Encontrar un usuario por correo electrónico o nombre de usuario como (@docuusername)',
      SELECTEDUSERS: 'Usuarios seleccionados',
      ROLEPERMISSION: 'Permisos de Rol',
    },
    GLOBALTEXTS: {
      HELLO: 'Hola',
      PLEASEFILLINFO: '¡Hey!, Ingresa tu información de usuario...',
      ACTIVE: 'Activo',
      DISABLED: 'Inactivo',
      LANGUAGE: 'Idioma',
      NAME: 'Nombre',
      LASTNAME: 'Apellidos',
      ADDRESS: 'Dirección',
      WEBSITE: 'Sitio Web',
      COUNTRY: 'País',
      TIMEZONE: 'Zona Horaria',
      CURRENCY: 'Moneda',
      DOCUUSERNAME: 'Nombre de Usuario',
      SAVE: 'Guardar',
      PLEASEWAIT: 'Por favor espere',
      GENERALDESCRIPTION: 'Descripción General',
      KNOWMORE: 'Conocer más',
      DEACTIVATE: 'Desactivar',
      MANAGEMEMBERS: 'Gestionar Miembros',
      FINISH: 'Finalizar',
      MEMBERSOF: 'Miembros de',
      CLOSE: 'Cerrar',
      SEARCHNOTFOUND: 'No se encontraron resultados para la búsqueda: "{{keyword}}"',
      SELECT: 'Seleccionar',
      REMOVE: 'Quitar',
      SHARENOW: 'Compartir Ahora',
      WITHACCESS: 'Con Acceso',
    }
  }
};
