import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, deleteUser, getAuth, sendEmailVerification, signInWithEmailAndPassword, signOut, updatePassword, UserCredential } from '@angular/fire/auth';

import { Router } from '@angular/router';

import { Observable, catchError, from, map, of, switchMap, tap, throwError } from 'rxjs';
import { UserAccess, UserData, UserLogin, UserRegister } from '../interfaces/user.interface';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';

import { jwtDecode } from 'jwt-decode'; // Importación correcta
import { UserSessionService } from '@pages/user/services/user-session.service';
import { GoogleAuthProvider, OAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { Firestore, getDocs, query, where } from '@angular/fire/firestore';
import { collection } from 'firebase/firestore';
import { FireStoreCollectionsService } from 'src/app/shared/services/fire-store-collections.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private googleProvider = new GoogleAuthProvider();
  private microsoftProvider = new OAuthProvider('microsoft.com');

  constructor(
    private auth: Auth,
    private router: Router,
    private sessionService: SessionStorageService,
    private userSessionService: UserSessionService,
    private firestore: Firestore,
    private fsCollectionSvc:FireStoreCollectionsService
  ) { }

  getAuthState(): Observable<User | null> {
    return new Observable<User | null>((observer) => {
      const unsubscribe = this.auth.onAuthStateChanged(
        (user) => observer.next(user),
        (error) => observer.error(error),
        () => observer.complete()
      );

      // Cleanup function
      return { unsubscribe };
    });
  }

  loginWithEmailAndPassword(user: UserLogin): Observable<any> {
    return from(signInWithEmailAndPassword(this.auth, user.email, user.password)).pipe(
      map((userCredential: any) => {
        const userData = {
          accessToken: userCredential.user.accessToken,
          uid: userCredential.user.uid,
          email: user.email,
          emailVerified: userCredential.user.emailVerified
        }
        console.log(userCredential)

        return userData;
      }),
      catchError((error: any) => {
        console.error('Error en el login:', error);
        throw error; // Propaga el error para manejarlo en un nivel superior si es necesario
      })
    );
  }

  createUserWithEmailAndPassword(email: string, password: string): Observable<any> {
    const user = this.auth.currentUser;
    console.log(user)
    return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
      map((userCredential: any) => {
        console.log(userCredential)
        const userData: UserAccess = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken
        };
        return userData;
      }),
      catchError((error: any) => {
        console.error('Error al crear usuario:', error);
        throw error;
      })
    );
  }

  sendEmailVerification(): void {
    if (this.auth.currentUser) {
      sendEmailVerification(this.auth.currentUser)
        .then(() => {
          console.log('Email')
        });

    }
  }

  changePassword(currentPassword: string, newPassword: string): Observable<void> {
    const user = this.auth.currentUser;
    if (!user) {
      return throwError(() => new Error('No hay usuario autenticado.'));
    }

    // Primero, necesitamos reautenticar al usuario
    return from(signInWithEmailAndPassword(this.auth, user.email ?? '', currentPassword)).pipe(
      switchMap(() => {
        // Ahora que la reautenticación es exitosa, actualizamos la contraseña
        return from(updatePassword(user, newPassword));
      }),
      tap(() => {
        console.log('Contraseña actualizada exitosamente.');
        this.logout().subscribe()
      }),
      catchError((error: any) => {
        console.error('Error al actualizar la contraseña:', error);
        return throwError(() => new Error('Error al actualizar la contraseña.'));
      })
    );
  }


  logInWithGoogle(): Observable<any> {
    return from(signInWithPopup(this.auth, this.googleProvider)).pipe(
      map((userCredential: any) => {
        console.log(userCredential)
        const userData: UserAccess = {
          uid: userCredential.user.uid,
          accessToken: userCredential.user.accessToken,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName
        };
        const user = this.auth.currentUser;
        console.log(user)
        return userData

      }),
      catchError(error => {
        console.error('Error during Google sign-in:', error);
        throw error; // Re-throw the error to be handled by the component
      })
    );
  }

  loginWithMicrosoft(): Observable<UserAccess> {

    return from(signInWithPopup(this.auth, this.microsoftProvider)).pipe(
      map((result: any) => {
        const credential = OAuthProvider.credentialFromResult(result);
        console.log(credential)
        const userData: UserAccess = {
          uid: result.user.uid,
          accessToken: credential?.idToken ? credential.idToken : '',
          email: result.user.email
        };
        const user = this.auth.currentUser;
        console.log(user)
        return userData;
      }),
      catchError(error => {
        console.error('Error during Microsoft sign-in:', error);
        throw error; // Re-throw the error to be handled by the component
      })
    );
  }

  updateToken(): Observable<string | null> {
    return this.getAuthState().pipe(
      switchMap((user: User | null) => {
        if (!user) {
          console.log('No hay usuario autenticado.');
          return of(null);
        }
  
        //console.log('Usuario autenticado:', user);
  
        return from(user.getIdToken(true)).pipe(
          map((token: string) => {
            //console.log('Token obtenido:', token);
  
            const userAccess = this.sessionService.getObject('userAccess') || {};
            const updatedUserAccess = {
              ...userAccess,
              accessToken: token
            };
  
            this.sessionService.setObject('userAccess', updatedUserAccess);
            this.userSessionService.setUserAccess(updatedUserAccess);
  
            return token;
          }),
          catchError(error => {
            console.error('Error al actualizar el token:', error);
            return of(null);
          })
        );
      })
    );
  }
  
  


  isTokenExpired(token: string): boolean {
    try {
      const decoded: any = jwtDecode(token);
      const exp = decoded.exp;
      const now = Math.floor(new Date().getTime() / 1000);
      return now >= exp;
    } catch (error) {
      //console.error('Error al decodificar el token:', error);
      return true;
    }
  }

  checkTokenValidity() {
    const user: UserAccess = this.sessionService.getObject('userAccess');
    
    if (user && this.isTokenExpired(user.accessToken)) {
      this.updateToken().subscribe({
        next: (newToken) => {
          //console.log(newToken)
          if (newToken) {
            //console.log("Nuevo token")
            this.userSessionService.setUserAccess({
              ...user,
              accessToken: newToken
            });
          } else {
            console.log("saliendo en check")
            this.logout().subscribe();
          }
        },
        error: (error) => {
          console.error('Error al actualizar el token:', error);
          this.logout().subscribe();
        }
      });
    } else {
      this.userSessionService.setUserAccess(user);
    }
  }

  logout(): Observable<void> {
    return from(signOut(this.auth)).pipe(
      tap(() => {
        //console.log('Usuario desconectado exitosamente.');
        // Limpiar cualquier dato de sesión si es necesario
        this.userSessionService.setUser(null)
        this.sessionService.removeItem('userAccess');
        this.router.navigate(['auth/login']);
      }),
      catchError((error: any) => {
        //console.error('Error al cerrar sesión:', error);
        return throwError(() => new Error('Error al cerrar sesión.'));
      })
    );
  }

  checkUsernameExists(username: string, currentUserId: string): Observable<boolean> {
    const publicUsersRef = collection(this.firestore, this.fsCollectionSvc.worldUsers);
    const q = query(
      publicUsersRef, 
      where('username', '==', username),
      where('uid', '!=', currentUserId) // Ignora el documento del usuario actual
    );

    return from(getDocs(q)).pipe(
      switchMap(snapshot => {
        return of(!snapshot.empty); // Retorna true si el username existe, false si no existe
      })
    );
  }

}